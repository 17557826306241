/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'gender-male': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M9.5 2a.5.5 0 010-1h5a.5.5 0 01.5.5v5a.5.5 0 01-1 0V2.707L9.871 6.836a5 5 0 11-.707-.707L13.293 2zM6 6a4 4 0 100 8 4 4 0 000-8"/>',
    },
});
